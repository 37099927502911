(function () {
  "use strict";
  angular
    .module("marketPlace", [
      // Third party dependencies (mostly in alphabetical order)
      "720kb.socialshare",
      "angular-cache",
      "angular-toastr-flash",
      "angularMoment",
      "countrySelect",
      "http-auth-interceptor",
      "ngAnimate",
      "ngAside",
      "ngCookies",
      "ngFileUpload",
      "ngMagnify",
      "ngMap",
      "ngPromiseExtras",
      "ngRaven",
      "ngResource",
      "ngSanitize",
      "ngYoutubeEmbed",
      "satellizer",
      "toastr",
      "ui.bootstrap",
      "ui.bootstrap.showErrors",
      "uiCropper",
      "ui.router",
      "ui.router.tabs",
      "ui.select",
      "updateMeta",
      "validation.match",

      // ui-router-extras must come after ui-router
      // 'ct.ui.router.extras',

      // angular-permission must come after ui-router-extras
      "permission",
      "permission.ui",

      "headroom",

      // Define our dependencies after all third party ones
      // "marketPlace.address",
      "marketPlace.analytics",
      // "marketPlace.association",
      // "marketPlace.auction",
      "marketPlace.auth",
      "marketPlace.broadstreet",
      // "marketPlace.cart",
      "marketPlace.category",  // TODO only required by header, probably search
      "marketPlace.cms",
      // "marketPlace.comments",
      "marketPlace.constants",
      "marketPlace.content",
      // "marketPlace.currency",
      "marketPlace.dashboard",
      "marketPlace.email",
      // "marketPlace.gallery",
      "marketPlace.games",  // TODO required by dashboard
      // "marketPlace.events",
      // "marketPlace.following",
      "marketPlace.home",
      "marketPlace.invites",  // required by auth (join)
      // "marketPlace.items",
      // "marketPlace.listings",
      "marketPlace.lodash",
      // "marketPlace.moderation",
      "marketPlace.niches",
      "marketPlace.organisations",  // required by auth
      // "marketPlace.payments",
      "marketPlace.preview",  // required by auth
      // "marketPlace.profile",
      "marketPlace.referral",
      "marketPlace.searches",
      "marketPlace.sites",
      // "marketPlace.social_media_links",
      // "marketPlace.vimeo",
      // "marketPlace.watchdog",
    ])
    .run(runBlock)
    .constant("AUTH_EVENTS", {
      loginStarted: "auth-login-started",
      loginSuccess: "auth-login-success",
      loginFailed: "auth-login-failed",
      logoutSuccess: "auth-logout-success",
      sessionTimeout: "auth-session-timeout",
      notAuthenticated: "auth-not-authenticated",
      notAuthorized: "auth-not-authorized",
    })
    .constant("DATA_EVENTS", {
      followingChanged: "following-changed",
      userSearchesChanged: "user-searches-changed",
      queueRefreshAds: "queue-refresh-ads",
      galleryParamsChange: "gallery-params-change",
      cartUpdated: "cart-updated",
      galleryHeaderEvent: "set-gallery-header",
      showFooter: "show-footer",
    })
    .constant(
      "displayTypes",
      (function () {
        const displayTypes = [
          { slug: "social", name: "Social" },
          { slug: "commercial", name: "Commercial" },
        ];

        const enableGames /* eslint-disable no-undef */ =
          process.env.FEATURE_GAMES !== undefined ? JSON.parse(process.env.FEATURE_GAMES.toLowerCase()) : true;
        if (enableGames) {
          displayTypes.push({ slug: "game", name: "Game" });
        }

        const enableAuctions /* eslint-disable no-undef */ =
          process.env.FEATURE_AUCTIONS !== undefined ? JSON.parse(process.env.FEATURE_AUCTIONS.toLowerCase()) : true;
        if (enableAuctions) {
          displayTypes.push({ slug: "auction", name: "Auction" });
        }
        return displayTypes;
      })()
    )

    .value("displayTypes");

  runBlock.$inject = [
    "$rootScope",
    "$state",
    "$log",
    "$timeout",
    "AuthService",
    "PermPermissionStore",
    "CacheFactory",
    "PermRoleStore",
    "$auth",
    "moment",
    "broadstreet",
    "toastr",
    "$window",
    "$transitions",
    "$trace",
    "$uiRouter",
    "ENV",
    "DATA_EVENTS",
    "$document",
  ];

  function runBlock(
    $rootScope,
    $state,
    $log,
    $timeout,
    AuthService,
    PermPermissionStore,
    CacheFactory,
    PermRoleStore,
    $auth,
    moment,
    broadstreet,
    toastr,
    $window,
    $transitions,
    $trace,
    $uiRouter,
    ENV,
    DATA_EVENTS,
    $document
  ) {
    $uiRouter.urlService.rules.initial("/content/home");
    $uiRouter.urlService.rules.otherwise("/content/home");
    $uiRouter.urlService.config.caseInsensitive(true);
    $uiRouter.urlService.config.strictMode(false);

    function removeLoader() {
      const element = $window.document.getElementById("loader-container");
      if (element) {
        element.className = "hidden";
      }
    }

    moment.updateLocale("en", {
      calendar: {
        lastDay: "[Yesterday at] LT",
        sameDay: "[Today at] LT",
        nextDay: "[Tomorrow at] LT",
        lastWeek: "[last] dddd [at] LT",
        nextWeek: "dddd [at] LT",
        sameElse: "LT on MMMM Do",
      },
    });

    $log.debug("Define permissions");
    const permissions = [
      "add_organisation",
      "add_organisation_website",
      "change_organisation",
      "change_organisation_feature_boost",
      "change_organisation_website",
      "view_organisation_contact_details",
      "add_organisationimage",
      "change_organisationimage",
      "publish_organisation",
      "add_organisationuser",
      "add_associationrequest",
      "change_associationrequest",
      "add_clubrequest",
      "change_clubrequest",
      "add_association",
      "change_association",
      "add_club",
      "change_club",
      "require_approval",
      "require_birthday",
      "require_gender",
      "add_customuser",
      "add_profile_photo",
      "add_referral",
      "add_sub_donation",
      "add_subscription",
      "add_user_address",
      "change_customuser",
      "change_profile_photo",
      "change_user_address",
      "search_member_id",
      "add_tradereference",
      "change_tradereference",
      "add_category",
      "change_category",
      "add_double_item",
      "add_item",
      "add_item_price",
      "add_item_url",
      "add_item_video",
      "add_item_video_url",
      "bulk_add_item",
      "can_schedule_item_listing",
      "change_item",
      "change_any_item",
      "change_item_feature_boost",
      "change_item_price",
      "change_item_url",
      "change_item_video",
      "change_item_video_url",
      "add_dependant",
      "change_dependant",
      "add_event",
      "add_event_url",
      "change_event",
      "change_any_event",
      "change_event_feature_boost",
      "change_event_url",
      "add_subscription",
      "add_creditor",
      "change_creditor",
      "add_pledge",
      "change_pledge",
      "add_association",
      "change_association",
      "add_photo_for_sale",
    ];
    PermPermissionStore.defineManyPermissions(permissions, function (permissionName) {
      return AuthService.hasPermission(permissionName);
    });

    PermPermissionStore.definePermission("canList", [
      "$q",
      "Transaction",
      function ($q, Transaction) {
        const deferred = $q.defer();
        Transaction.summary(function (response) {
          if (
            response.max_listings_per_month === null ||
            response.total_transaction_count < response.max_listings_per_month
          ) {
            deferred.resolve();
          } else {
            deferred.reject();
          }
        });
        return deferred.promise;
      },
    ]);

    PermRoleStore.defineRole("ITEM_CREATOR", ["add_item", "canList"]);

    PermRoleStore.defineRole("EVENT_CREATOR", ["add_event", "canList"]);

    $log.debug("Define roles");
    PermRoleStore.defineManyRoles({
      ORG_USER: function () {
        return AuthService.isOrganisationUser();
      },
      LOGGED_IN: function () {
        return AuthService.isAuthenticated();
      },
      ADMIN: function () {
        return AuthService.isAdmin();
      },
    });

    if (ENV !== "production") {
      $log.debug("enable trace");
      $trace.enable("TRANSITION"); // log all transitions to console
    }

    $transitions.onCreate({}, function (transition) {
      $log.debug("CREATE", $uiRouter.urlService.url(), transition.params("to"), transition.params("from"));
    });

    $transitions.onBefore({}, function (transition) {
      const toState = transition.to();
      const toParams = Object.assign({}, transition.params("to"));
      if (
        transition.to().data.isGallery &&
        toParams.rawId === null &&
        toParams.id !== null &&
        toParams.itemType !== null
      ) {
        $log.debug("onBefore settings rawID", toParams.rawId, toParams.id, toParams.itemType);
        // rawId should always be set
        toParams.itemType = null;
        toParams.id = null;
        toParams.itemSlug = null;
        return transition.router.stateService.target(transition.to(), toParams);
      }

      const fromParams = transition.params("from");
      $log.debug("ONBEFORE RELOAD", transition.options().reload);
      if (
        !transition.options().reload &&
        transition.to().data.isGallery &&
        toParams.rawId !== null &&
        ((fromParams.caption && fromParams.caption !== toParams.caption) ||
          (fromParams.gallery && fromParams.gallery !== toParams.gallery) ||
          (fromParams.section && fromParams.section !== toParams.section))
      ) {
        toParams.detailFix = true;
        return transition.router.stateService.target(transition.to(), toParams, { reload: "home.gallery" });
      }

      // TODO load home.gallery state behind login/registers modals when accessed directly
      if (toState.data && toState.data.requiresLogin && !$auth.isAuthenticated()) {
        $log.debug("Not authenticated: Access denied", toState.name);
        return transition.router.stateService.target("login", { showLogin: false }, { reload: true });
      }
    });

    $transitions.onSuccess({}, function (transition) {
      if (transition.to().data.isGallery) {
        // Don't broadcast a params update if we're just opening a detail view in a gallery
        // TODO how to stop the params update on close?
        if ((!transition.params("to").rawId && !transition.params("to").item) || transition.from().name === "") {
          $log.debug("Broadcasting DATA_EVENTS.galleryParamsChange");
          $rootScope.$broadcast(DATA_EVENTS.galleryParamsChange, transition.params("to"), transition.params("from"));
        }
        $rootScope.$broadcast(DATA_EVENTS.queueRefreshAds);
      }
    });

    $transitions.onError({}, function (transition) {
      $log.debug("ERROR", transition);
      const rejection = transition.error();

      // RejectType.ERROR (skip all other error types)
      if (rejection.type === 6 && rejection.detail) {
        const error = rejection.detail;
        const stateService = transition.router.stateService;
        const fromState = transition.from();
        const toState = transition.to();

        if (error.hasOwnProperty("redirectTo")) {
          stateService.go(error.redirectTo, error.redirectParams);
          return;
        }

        if (error.hasOwnProperty("status")) {
          if (error.status === -1) {
            return;
          }

          if (error.status === 404 && fromState.data && fromState.data.isGallery) {
            // Show warning if we're in a gallery state and reload the gallery
            toastr.warning("Sorry that item is not available right now.", "Not Found");
            stateService.reload();
            return;
          }

          if (error.status === 404) {
            if (typeof $window.gtag === 'function') {
              $window.gtag('event', 'page_view', {
                page_location: stateService.href(toState, transition.params()),
              });
            }
            stateService.go("home.not-found");
            return;
          }

          if (error.status === 403 && fromState.name === "" && toState.name.startsWith("home.gallery")) {
            if (typeof $window.gtag === 'function') {
              $window.gtag('event', 'page_view', {
                page_location: stateService.href(toState.name, transition.params()),
              });
            }
            stateService.go("home.permission-denied");
            return;
          }
        }

        stateService.go("home.gallery", {}, { reload: true, inherit: false });
        toastr.error("Sorry an error occurred");
      }
    });

    $rootScope.$on(DATA_EVENTS.queueRefreshAds, function () {
      $log.debug("clearing campaign logs");
      broadstreet.clearCampaignLog();
      $timeout(function () {
        const inactivityModals = $document[0].querySelectorAll("[class^=street-dialog-id]");
        $log.debug("Removing inactivity modals", inactivityModals);
        Array.prototype.forEach.call(inactivityModals, function (node) {
          node.parentNode.removeChild(node);
        });
      });
    });

    $rootScope.$on("event:auth-loginRequired", function () {
      // eslint-disable-line angular/on-watch
      $log.debug("Got 401 from api");
      if (AuthService.isAuthenticated()) {
        // logout removing any old token and cached promises
        AuthService.logout(true, true, false);
      } else {
        // show login modal
        $state.go("login", { showLogin: false }, { reload: true });
      }
    });

    $rootScope.$on("auth-login-success", function () {
      // eslint-disable-line angular/on-watch
      const menuCache = CacheFactory.get("menuCache");
      if (menuCache) {
        $log.debug("Clearing menu cache");
        menuCache.removeAll();
      }

      const nicheCache = CacheFactory.get("readableNicheCache");
      if (nicheCache) {
        $log.debug("Clearing readable niche cache");
        nicheCache.removeAll();
      }
    });

    removeLoader();
    //uncomment this to log ui-router errors to the console
    //$rootScope.$on("$stateChangeError", console.log.bind(console));
  }
})();

// disable eslint from here down as these are polyfills
/* eslint-disable */
if (!Array.prototype.includes) {
  Array.prototype.includes = function (searchElement /*, fromIndex*/) {
    "use strict";
    var O = Object(this);
    var len = parseInt(O.length) || 0;
    if (len === 0) {
      return false;
    }
    var n = parseInt(arguments[1]) || 0;
    var k;
    if (n >= 0) {
      k = n;
    } else {
      k = len + n;
      if (k < 0) {
        k = 0;
      }
    }
    var currentElement;
    while (k < len) {
      currentElement = O[k];
      if (searchElement === currentElement || (searchElement !== searchElement && currentElement !== currentElement)) {
        // NaN !== NaN
        return true;
      }
      k++;
    }
    return false;
  };
}

if (!String.prototype.startsWith) {
  String.prototype.startsWith = function (searchString, position) {
    position = position || 0;
    return this.substr(position, searchString.length) === searchString;
  };
}

if (!String.prototype.endsWith) {
  String.prototype.endsWith = function (searchStr, Position) {
    // This works much better than >= because
    // it compensates for NaN:
    if (!(Position < this.length)) Position = this.length;
    else Position |= 0; // round position
    return this.substr(Position - searchStr.length, searchStr.length) === searchStr;
  };
}

if (!String.prototype.slugify) {
  String.prototype.slugify = function (override) {
    // This works much better than >= because
    // it compensates for NaN:
    var val = (override || this).toLowerCase();
    val = val.replace(/[^\w\s-]/g, "");
    val = val.replace(/[-\s]+/g, "-");
    return val;
  };
}

window.requestAnimFrame = (function () {
  return (
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    function (/* function */ callback, /* DOMElement */ element) {
      window.setTimeout(callback, 1000 / 60);
    }
  );
})();
/* eslint-enable */
