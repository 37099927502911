import { ILogService } from "angular";

angular.module("marketPlace.home").component("footerSection", {
  template: require("./templates/footer.html"),
  controller: footerController
});

footerController.$inject = ["$log", "CMS_URL", "SITE_NAME", "socialMediaLinks", "Pages", "AppConfig"];

function footerController(
  $log: ILogService,
  CMS_URL: string,
  SITE_NAME: string,
  socialMediaLinks: { [key: string]: string },
  Pages,
  AppConfig
) {
  const $ctrl = this;

  $ctrl.$onInit = function() {
    $log.debug("footer $onInit");
    $ctrl.CMS_URL = CMS_URL;
    $ctrl.siteName = SITE_NAME;
    $ctrl.socialMediaLinks = socialMediaLinks;
    $ctrl.currentDate = new Date();
    if (AppConfig.S3Config.s3url && AppConfig.customLogo) {
      $ctrl.customLogo = AppConfig.S3Config.s3url + AppConfig.customLogo;
    }

    Pages.query().then(function(resp) {
      const contentPages = [];
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i].include_in_footer) {
          contentPages.push(resp.data[i]);
        }
      }
      $ctrl.contentPages = contentPages;
    });
  };
}
