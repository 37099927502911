// Analytics
import "./app/analytics/analytics.module.ts";
import "./app/analytics/analytics.service.ts";

// import "./app/associations/association.module.ts";
// import "./app/associations/association.service.ts";

// Auctions
// import "./app/auction/auction.module";
// import "./app/auction/auction-dashboard.component";
// import "./app/auction/auction-section.component";
// import "./app/auction/auction.routes";
// import "./app/auction/lot.service";

// Auth
import "./app/auth/auth.module.ts";
import "./app/auth/auth.service.ts";
import "./app/auth/compare-to.directive.ts";
import "./app/auth/groups.service.ts";
import "./app/auth/previous.service.ts";

// Auth - Activate
import "./app/auth/activate/activate.routes.ts";
import "./app/auth/server-error.directive.ts";
import "./app/auth/user.service.ts";

// Auth - Change Password
import "./app/auth/change-password/changePassword-modal.controller.ts";

// Auth - Login
import "./app/auth/login/login-modal.controller.ts";
import "./app/auth/login/login.routes.ts";

// Auth - Registration
import "./app/auth/register/organisation-type.component.ts";
import "./app/auth/register/register-modal.component.ts";
import "./app/auth/register/register.routes.ts";
import "./app/auth/register/registration.service.ts";
import "./app/auth/register/user-type.component.ts";

// Auth - Notifications
import "./app/auth/notifications.service.ts";
import "./app/auth/notifications/notifications.controller.ts";
import "./app/auth/notifications/notifications.routes.ts";

// Auth - Reset Password
import "./app/auth/reset-password/reset-password-confirm.controller.ts";
import "./app/auth/reset-password/reset-password.controller.ts";
import "./app/auth/reset-password/reset-password.routes.ts";

// Broadstreet
import "./app/broadstreet/broadstreet.module.ts";
import "./app/broadstreet/broadstreet.factory.ts";
import "./app/broadstreet/broadstreet.service.ts";
import "./app/broadstreet/broadstreet-ad.component.ts";

// Category
import "./app/category/category.module.ts";
import "./app/category/available-tags.service.ts";
import "./app/category/category.service.ts";
import "./app/category/groupcategory.service.ts";

// Cart
// import "./app/cart/cart.module.ts";
// import "./app/cart/cart.service.ts";
// import "./app/cart/cart.routes.ts";
// import "./app/cart/cart.controller.ts";

// CMS
import "./app/cms/cms.module.ts";
import "./app/cms/menu.service.ts";

// Comments
// import "./app/comments/comments.module.ts";
// import "./app/comments/event-comment.service.ts";
// import "./app/comments/item-comment.service.ts";

// Preview
import "./app/preview/preview.module.ts";
import "./app/preview/preview.service.ts";
import "./app/preview/preview.controller.ts";
import "./app/preview/thumbnail.service.ts";
import "./app/preview/image-thumbnail.component";

// Common
import "./app/common/common.module.ts";
import "./app/common/checkmark.filter.ts";
import "./app/common/custom-angular.headroom.ts";
import "./app/common/titlecase.directive.ts";
import "./app/common/titlecase.filter.ts";
import "./app/common/nicenum.ts";
import "./app/common/trusted.filter.ts";
import "./app/common/iso-currency.filter.ts";
import "./app/common/angular-toastr-flash.factory";
import "./app/common/gallery-data.service.ts";

// // Common - Address
// import "./app/common/address/address.module.ts";
// import "./app/common/address/address.service.ts";

// Common - Controls
import "./app/common/controls/controls.module.ts";

// Common - Controls - Comment Section
import "./app/common/controls/commentSection/auto-resize.directive.ts";
import "./app/common/controls/commentSection/comments.directive.ts";

// Common - Controls DetailPane
import "./app/common/controls/detailpane/detailpane.directive.ts";

// Common - Controls - Gallery Link
import "./app/common/controls/galleryLink/gallery-link.directive.ts";

// Common - Controls - Game Section
import "./app/common/controls/gameSection/game.directive.ts";

// Common - Controls - ImageCropUpload
import "./app/common/controls/imagecropupload/imagecropupload.directive.ts";

// Common - Controls - Infinite Scroll
import "./app/common/controls/infinitescroll/infinitescroll.directive.ts";

// Common - Controls - JSON LD
import "./app/common/controls/json-ld/json-ld.component";

// Common - Controls - Loading Pane
import "./app/common/controls/loadingPane/loadingPane.directive.ts";

// Common - Controls - Media Section
import "./app/common/controls/mediaSection/media-section.directive.ts";

// Common - Controls - Niche Accordion
import "./app/common/controls/nicheaccordion/niche-accordion.component.ts";

// Common - Controls - Payment
import "./app/common/controls/payment/payment.directive.ts";

// Common - Controls - Permission Denied
import "./app/common/controls/permissionDeniedPane/permissionDeniedPane.directive.ts";

// Common - Controls - Promote Listing
import "./app/common/controls/promoteListing/promoteListing.directive.ts";

// Common - Controls - Tile
import "./app/common/controls/tile/tile.component";

// Common - Controls - Lodash
import "./app/common/lodash/lodash.module.ts";
import "./app/common/lodash/lodash.factory.ts";

// Content
import "./app/content/content.module.ts";
import "./app/content/contact-us.component.ts";
import "./app/content/contact-us.service.ts";
import "./app/content/content.routes.ts";
import "./app/content/page.component.ts";
import "./app/content/page.service.ts";

// Currency
// import "./app/currency/currency.module.ts";
// import "./app/currency/currency.service.ts";

// Dashboard
import "./app/dashboard/dashboard.module.ts";
import "./app/dashboard/dashboard-nav.component.ts";
import "./app/dashboard/dashboard-table.service.ts";
import "./app/dashboard/dashboard.controller.ts";
import "./app/dashboard/dashboard.routes.ts";
import "./app/dashboard/dashboard.services.ts";
import "./app/dashboard/expand.directive.ts";

// Dashboard Billing
import "./app/dashboard/billing/billing.controller.ts";
import "./app/dashboard/billing/billing.routes.ts";

// Email
import "./app/email/email.module.ts";
import "./app/email/email.routes.ts";
import "./app/email/email.service.ts";
import "./app/email/email.controller.ts";
import "./app/email/schedule.service.ts";
import "./app/email/addEmailNotification-modal.controller.ts";

// Events
// import "./app/events/events.module.ts";
// import "./app/events/events.routes.ts";
// import "./app/events/events.service.ts";
// import "./app/events/events.add.controller.ts";
// import "./app/events/events.edit.controller.ts";
// import "./app/events/events.form.service.ts";

// Following
// import "./app/following/following.module.ts";
// import "./app/following/following.service.ts";

// Searches
import "./app/home/home.module.ts";
import "./app/searches/searches.module.ts";
import "./app/searches/searches.service.ts";
import "./app/searches/user-search.service.ts";
import "./app/home/save-search.component";
import "./app/home/register-search.component";

// Gallery
// import "./app/gallery/gallery.module.ts";
// import "./app/gallery/gallery.routes.ts";
// import "./app/gallery/gallery.service.ts";
// import "./app/gallery/gallery.form.service.ts";
// import "./app/gallery/gallery.controller.ts";
// import "./app/gallery/breadcrumb.directive.ts";
// import "./app/gallery/gallery-item.service.ts";
// import "./app/gallery/sort.directive.ts";
// import "./app/gallery/type-filter.component.ts";

// Games
import "./app/games/game.module.ts";
import "./app/games/game.routes.ts";
import "./app/games/game.service.ts";
import "./app/games/game-results.controller.ts";
import "./app/games/leaderboard.controller.ts";
import "./app/games/my-results.controller.ts";

// Home
import "./app/home/home.routes.ts";
import "./app/home/menu.controller.ts";
import "./app/home/advanced-search.controller.ts";
import "./app/home/footer.component.ts";
import "./app/home/gallery-header.service.ts";
import "./app/home/gallery-header.component.ts";
import "./app/home/header.controller.ts";
import "./app/home/home.controller.ts";
import "./app/home/countryname.service.ts";

// following must be imported after home.module.ts
import "./app/auth/login/login.component.ts";
import "./app/auth/register/simple-register.component.ts";
import "./app/common/address/address.component.ts";

// Invites
import "./app/invites/invites.module.ts";
import "./app/invites/invites.routes.ts";
import "./app/invites/invites.service.ts";
import "./app/invites/invites.controller.ts";

// Items
// import "./app/items/items.module.ts";
// import "./app/items/items.routes.ts";
// import "./app/items/items.service.ts";
// import "./app/items/items.add.controller.ts";
// import "./app/items/items.edit.controller.ts";
// import "./app/items/items.form.service.ts";
// import "./app/items/item-share.component.ts";

// Listings
// import "./app/listings/listings.module.ts";
// import "./app/listings/listings.routes.ts";
// import "./app/listings/listings.service.ts";
// import "./app/listings/listings.controller.ts";
// import "./app/listings/event-listings.controller.ts";
// import "./app/listings/listings-relist.controller.ts";

// Constants
import "./app/ngConstants.ts"; // local

// Moderation
// import "./app/moderation/moderation.module.ts";
// import "./app/moderation/moderation.routes.ts";
// import "./app/moderation/moderation.service.ts";
// import "./app/moderation/approve-comment.controller.ts";
// import "./app/moderation/approve-item.controller.ts";

// Niches
import "./app/niches/niches.module.ts";
import "./app/niches/niches.service.ts";

// Orders
import "./app/dashboard/orders/orders.controller.ts";
import "./app/dashboard/orders/orders.routes.ts";

// Organisation
import "./app/organisations/organisations.module.ts";
import "./app/organisations/organisation.service.ts";
import "./app/organisations/organisation.form.service.ts";
import "./app/organisations/organisations.routes.ts";
import "./app/organisations/dashboard-organisation.controller.ts";
import "./app/organisations/organisation-users.controller.ts";
import "./app/organisations/organisation-users.service.ts";

// Payment
// import "./app/payments/payments.module.ts";
// import "./app/payments/payments.service.ts";
// import "./app/payments/payments.controller.ts";
// import "./app/payments/braintree.factory.ts";
// import "./app/payments/business-group-choice.component.ts";
// import "./app/payments/change-payment-method.controller.ts";
// import "./app/payments/payment-method.service.ts";
// import "./app/payments/payments.form.service.ts";
// import "./app/payments/transactions.service.ts";
// import "./app/payments/upgrade-sub-modal.component.ts";
// import "./app/payments/upgrade-subscription.component.ts";
// import "./app/payments/plan-detail.component.ts";

// // Profile
// import "./app/profile/profile.module.ts";
// import "./app/profile/profile.routes.ts";
// import "./app/profile/dashboard-associations.controller.ts";
// import "./app/profile/dashboard-clubs.controller.ts";
// import "./app/profile/dashboard-profile.controller.ts";

// Referral
import "./app/referral/referral.module.ts";
import "./app/referral/referral.routes.ts";
import "./app/referral/referral.service.ts";
import "./app/referral/referral-list.controller.ts";
import "./app/referral/referral.controller.ts";

// Settings
import "./app/settings/settings.service.ts";

// Sites
import "./app/sites/sites.module.ts";
import "./app/sites/sites.service.ts";

// Social Media
// import "./app/social_media_links/social_media_links.module.ts";
// import "./app/social_media_links/link-type.service.ts";

// Vimeo
// import "./app/vimeo/vimeo.module.ts";
// import "./app/vimeo/vimeo.component.ts";
// import "./app/vimeo/vimeo.service.ts";

// // Watchdog
// import "./app/watchdog/watchdog.module.ts";
// import "./app/watchdog/watchdog.routes.ts";
// import "./app/watchdog/watchdog.controller.ts";
// import "./app/watchdog/watchdog.service.ts";

// Styles
// import "./app/css/style.scss";
// import "./app/common/float-label/float-label.css";
import "./madicons/style.css";
import "./app/css/app.scss";

// App
import "./app/app";
import "./app/app.config";
